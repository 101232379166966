// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("vendor/bootstrap.min")
require("vendor/jquery-ui-1.10.4.custom.min")
require("vendor/jquery.ui.touch-punch.min")
require("vendor/detect")
require("vendor/jquery.animateNumbers")
require("vendor/ios7.switch")
require("vendor/fastclick")
require("vendor/jquery.blockUI")
require("vendor/bootbox.min")
require("vendor/jquery.slimscroll.min")
require("vendor/jquery-sparkline")
require("vendor/classie")
require("vendor/modalEffects")
require("vendor/sortable.min")
require("vendor/bootstrap.file-input")
require("vendor/bootstrap-select.min")
require("vendor/select2.min")
require("vendor/jquery.magnific-popup.min")
require("vendor/pace.min")
require("vendor/bootstrap-datepicker")
require("vendor/icheck.min")
require("vendor/prettify")
require("vendor/init")
require("vendor/morris.min")
require("vendor/chart.min")
require("vendor/chartjs-plugin-labels.min")
require("vendor/jquery.knob.min")
require("vendor/jquery-jvectormap-1.2.2.min")
require("vendor/jquery-jvectormap-us-aea-en")
require("vendor/clock")
require("vendor/jquery.easypiechart.min")
require("vendor/jquery.simpleWeather-2.6.min")
require("vendor/bootstrap-editable.min")
require("vendor/bic_calendar.min")
require("vendor/jquery.mask.min")
require("vendor/apps/calculator")
require("vendor/apps/todo")
require("vendor/apps/notes")
require("vendor/pages/index")
